import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "./logtable.css";
import React, { useEffect, useState } from "react";
import { useDarkMode } from "../../hooks/useDarkMode";

function SensorData(props) {
  const { selectedSensorData } = props;
  const darkMode = useDarkMode((state) => state.theme);

  const [colums, setColumns] = useState([
    // field value has key value from SOCKET_DATA_HEADERS_CSV in index.js
    {
      field: "RaspTime",
      filter: true,
      sortable: true,
      unSortIcon: true,
    },

    { field: "AngleRotation", filter: true, sortable: true, unSortIcon: true },
    { field: "TeethFSR", filter: true, sortable: true, unSortIcon: true },
    { field: "OesophagusReed", filter: true, sortable: true, unSortIcon: true },
    {
      field: "TracheaReedStart",
      filter: true,
      sortable: true,
      unSortIcon: true,
    },
    {
      field: "TracheaReedInter1",
      filter: true,
      sortable: true,
      unSortIcon: true,
    },
    {
      field: "TracheaReedInter2",
      filter: true,
      sortable: true,
      unSortIcon: true,
    },
    { field: "TracheaReedEnd", filter: true, sortable: true, unSortIcon: true },
    { field: "TracheaFSR", filter: true, sortable: true, unSortIcon: true },
    { field: "LeftLungBMP", filter: true, sortable: true, unSortIcon: true },
    { field: "healthScore", filter: true, sortable: true, unSortIcon: true },
    { field: "RightLungBMP", filter: true, sortable: true, unSortIcon: true },
  ]);

  return (
    <div
      className={`${
        darkMode === "dark" ? "ag-theme-balham-dark" : "ag-theme-balham"
      } p-2`} //applying the Data Grid theme
      style={{
        height: 500,
        width: "100%",
        overflowY: "scroll",
      }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact rowData={selectedSensorData} columnDefs={colums} />
    </div>
  );
}

export default SensorData;
