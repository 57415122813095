import { Close, Delete, Search } from "@mui/icons-material";
import { useEffect, useState } from "react";
import "./sessionContainer.css";
const SessionsContainer = (props) => {
  const {
    list,
    setFirstThreeSessionArray,
    firstThreeSessionArray,
    handleSessionSelection,
    setOpenSearchModal,
    alreadySelectedSessionInd,
    setAlreadySelectedSessionInd,
  } = props;
  const [optionArray, setOptionArray] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const addSessionOnTab = (item, ind) => {
    const updatedArray = [...firstThreeSessionArray, item];
    let getLasetIndexOfUpdatedArray = updatedArray.length - 1;
    handleSessionSelection(item, getLasetIndexOfUpdatedArray);
    let updatedSessionInd = [...alreadySelectedSessionInd, item.session_id];
    setAlreadySelectedSessionInd(updatedSessionInd);
    setFirstThreeSessionArray(updatedArray);
  };

  const removeSessionOnTab = (item, ind) => {
    const updatedArray = firstThreeSessionArray.filter(
      (tabItem) => tabItem.session_id !== item.session_id
    );
    let getLastIndexOfUpdatedArray = updatedArray.length - 1;
    //update the last item after deletion of current session
    handleSessionSelection(
      updatedArray[getLastIndexOfUpdatedArray],
      getLastIndexOfUpdatedArray
    );
    let updatedSessionInd = alreadySelectedSessionInd.filter(
      (selectedSession) => selectedSession !== item.session_id
    );
    setAlreadySelectedSessionInd(updatedSessionInd);
    setFirstThreeSessionArray(updatedArray);
  };

  useEffect(() => {
    let modifySearchTerm =
      typeof searchTerm === "string"
        ? searchTerm.trim().toLowerCase()
        : searchTerm.trim();
    let filteredArray = list.filter((session) => {
      return (
        session?.session_name.toLowerCase().includes(modifySearchTerm) ||
        session?.created_at.includes(modifySearchTerm)
      );
    });
    setOptionArray(filteredArray);
    // }
  }, [searchTerm, list]);

  return (
    <div className="search-session-modal">
      <div className="first-container relative flex flex-col gap-2 w-[50rem] p-6 lg:p-12 bg-backgroundModal drop-shadow-lg">
        <div className="first-child flex justify-center items-center gap-4 w-full px-2">
          <input
            type="text"
            placeholder="Search a session"
            className="p-2 w-full text-center drop-shadow-xl"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {/* <div
            className="flex justify-center items-center cursor-pointer px-2 py-1 h-full sm:w-[150px] text-xl bg-violet-600 rounded-sm text-textColor"
            onClick={() => setSearchTerm("")}
          >
            Clear
          </div> */}
        </div>
        {/* 2nd row starts */}
        <div className="second-child relative h-[400px] flex ">
          {/* -- 2nd row first column starts -- */}
          <div className="unselected-sessions-div flex flex-col justify-start items-center gap-2 w-[50%] p-2">
            <p className="text-xl text-textColor">Search results..</p>
            {searchTerm.length > 0 ? (
              <div className="h-[400px] w-full overflow-y-scroll p-2">
                {optionArray.length > 0 ? (
                  optionArray.map((item, ind) => (
                    <div className="flex justify-between p-2 text-textColor">
                      <div>
                        {ind + 1}. &nbsp;{item?.session_name} -{" "}
                        {item?.created_at.split("T")[0]}
                      </div>
                      {alreadySelectedSessionInd.includes(item.session_id) ? (
                        <button
                          className="bg-gray-500 text-btnText p-2 text-sm"
                          onClick={() => removeSessionOnTab(item, ind)}
                        >
                          Selected
                        </button>
                      ) : (
                        <button
                          className="p-2 bg-BgPrimary text-btnText text-sm"
                          onClick={() => addSessionOnTab(item, ind)}
                        >
                          Select
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <div>No results found...</div>
                )}
              </div>
            ) : (
              <div className="h-[400px] w-full overflow-y-scroll p-2">
                {list.length > 0 ? (
                  list.map((item, ind) => (
                    <div className="flex justify-between p-2 text-textColor">
                      <div>
                        {ind + 1}. &nbsp;{item?.session_name} -{" "}
                        {item?.created_at.split("T")[0]}
                      </div>
                      {alreadySelectedSessionInd.includes(item.session_id) ? (
                        <button
                          className="bg-gray-500 text-btnText p-2 text-sm"
                          onClick={() => removeSessionOnTab(item, ind)}
                        >
                          Selected
                        </button>
                      ) : (
                        <button
                          className="p-2 bg-BgPrimary text-btnText text-sm"
                          onClick={() => addSessionOnTab(item, ind)}
                        >
                          Select
                        </button>
                      )}
                    </div>
                  ))
                ) : (
                  <div>No results found...</div>
                )}
              </div>
            )}
          </div>
          {/* -- 2nd row first column ends ------ */}
          {/* show selected sessions -- updated firstThreeSessionArray */}
          <div className="flex flex-col justify-start items-center gap-2 w-[50%] p-2">
            <p className="text-xl text-textColor">Selected Sessions</p>
            <div className="h-[400px] w-full overflow-y-scroll flex flex-col gap-2">
              {firstThreeSessionArray.map((item, ind) => (
                <div
                  className={`w-full flex justify-between items-center py-1 px-2 text-sm font-medium text-textColor  ${
                    ind % 2 === 0 ? "bg-background text-btnText" : ""
                  }`}
                >
                  <div>
                    {ind + 1}. &nbsp;{item?.session_name}
                  </div>
                  {/* <div>{item?.created_at.split("T")[0]}</div> */}
                  <div>
                    {new Date(item?.created_at).toISOString().split("T")[0]}
                  </div>

                  <button
                    className="bg-BgPrimary text-btnText py-1 px-2 font-medium text-sm rounded-sm drop-shadow-md"
                    onClick={() => removeSessionOnTab(item, ind)}
                  >
                    Unselect
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* ------ 3rd row starts */}
        <div className="third-child flex w-full justify-end">
          <button
            onClick={() => setOpenSearchModal(false)}
            className="bg-BgPrimary text-btnText text-xl cursor-pointer px-4 py-1 "
          >
            Submit
          </button>
        </div>
        <span
          className="absolute top-1 right-2 cursor-pointer text-textColor"
          onClick={() => setOpenSearchModal(false)}
        >
          <Close />
        </span>
      </div>
    </div>
  );
};

export default SessionsContainer;
