import { create } from "zustand";
// Check localStorage or fallback to system preference
const savedTheme = localStorage.getItem("theme");
let mode = savedTheme ? savedTheme : "dark";
export const useDarkMode = create((set) => ({
  theme: mode,
  toggleTheme: () => {
    set((state) => {
      localStorage.setItem("theme", state.theme === "light" ? "dark" : "light");
      return { theme: state.theme === "light" ? "dark" : "light" };
    });
  },
}));
