const scenario = [
  {
    scenario: {
      patientDetails: {
        title: "Emergency Airway Management in the ER for 25 Year Old Man",
        name: "John Doe",
        age: 25,
        active: true,
        gender: "Male",
        weight: "25 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 25-year-old male with a known history of asthma, is presenting with progressively worsening difficulty breathing. Over the past two hours, he has exhibited severe respiratory distress characterized by rapid, shallow breathing and an audible wheezing sound, especially during exhalation. His caregivers report that his symptoms began suddenly after exposure to a potential allergen. Clinical signs such as stridor (a high-pitched, wheezing sound caused by disrupted airflow), intercostal retractions (visible pulling in of the chest wall during inhalation), and cyanosis (bluish discoloration of the skin, particularly around the lips and fingertips) indicate a significant impairment in oxygenation. The patient appears anxious and fatigued, further suggesting the seriousness of his respiratory condition.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "36 breaths/min",
          oxygenSaturation: "85%",
          bloodPressure: "90/60 mmHg",
        },
        symptoms: ["Stridor", "Intercostal retractions", "Cyanosis"],
      },
      management: {
        initialActions: ["Administered 100% oxygen"],
        interventions: {
          nebulizedMedications: { medication: "Albuterol", dose: "2.5 mg" },
          intubation: {
            indication: "Worsening distress",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Emergency Airway Management in the ER for 30 Year Old Man",
        name: "Chris Lee",
        age: 30,
        active: true,
        gender: "Male",
        weight: "68 kg",
        medicalHistory: {
          knownAllergies: "Penicillin",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient is a 30-year-old man who presents with acute chest pain and shortness of breath. He reports the sudden onset of sharp, stabbing pain in the left side of her chest that worsens with deep breaths and movement. He has a history of recent heavy physical activity and feels lightheaded. He describes a sensation of tightness in her chest, with difficulty taking a full breath. There is a concern for a possible pulmonary embolism, given the combination of pleuritic chest pain, dyspnea, and risk factors such as recent immobilization and strenuous exercise.",
        vitalSigns: {
          heartRate: "112 bpm",
          respiratoryRate: "22 breaths/min",
          oxygenSaturation: "92%",
          bloodPressure: "110/70 mmHg",
        },
        symptoms: ["Chest pain", "Shortness of breath", "Lightheadedness"],
      },
      management: {
        initialActions: ["Administered 100% oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Heparin",
            dose: "5,000 units bolus",
          },
          intubation: {
            indication: "Worsening distress",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Emergency Airway Management in the ER for 22 Year Old Man",

        name: "Brandon Clark",
        age: 22,
        active: true,
        gender: "Male",
        weight: "55 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Seizure Disorder",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient is a 22-year-old man brought to the ER after experiencing a prolonged seizure lasting more than 5 minutes, followed by unresponsiveness. Witnesses report that the patient had convulsions involving the entire body. Upon arrival, the patient is postictal, confused, and difficult to arouse. Clinical examination reveals cyanosis and labored breathing, raising concerns about airway compromise and hypoxia secondary to status epilepticus.",

        vitalSigns: {
          heartRate: "88 bpm",
          respiratoryRate: "18 breaths/min",
          oxygenSaturation: "98%",
          bloodPressure: "160/100 mmHg",
        },
        symptoms: ["Headache", "Blurred vision", "Swelling", "Dizziness"],
      },
      management: {
        initialActions: ["Administered 100% oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Labetalol",
            dose: "200 mg orally",
          },
          intubation: {
            indication: "Worsening distress",
            ETTubeSize: "5.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Trauma Management in the ER for 32-Year-Old Man",
        name: "David Johnson",
        age: 32,
        active: false,
        gender: "Male",
        weight: "85 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 32-year-old male, arrived at the ER after a high-speed motor vehicle collision. He was conscious but reported severe chest and abdominal pain. Physical examination revealed abrasions on the chest, diminished breath sounds on the left side, and tenderness in the right upper quadrant of the abdomen. The patient also exhibited tachypnea and hypotension, consistent with potential internal injuries.",
        vitalSigns: {
          heartRate: "120 bpm",
          respiratoryRate: "28 breaths/min",
          oxygenSaturation: "92%",
          bloodPressure: "85/50 mmHg",
        },
        symptoms: ["Chest pain", "Abdominal pain", "Tachypnea"],
      },
      management: {
        initialActions: [
          "Administered supplemental oxygen",
          "Initiated fluid resuscitation",
        ],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Worsening hypoxia and decreased consciousness",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Anaphylaxis Management for 28-Year-Old Man",
        name: "James Lee",
        age: 28,
        active: false,
        gender: "Male",
        weight: "70 kg",
        medicalHistory: {
          knownAllergies: "Peanuts",
          chronicConditions: "Seasonal Allergies",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 28-year-old male, presented to the ER following a rapid onset of difficulty breathing, swelling of the face and lips, and widespread urticaria after consuming food containing peanuts. He reported dizziness and near-syncope on arrival. Physical examination revealed generalized erythema, angioedema of the face, and wheezing bilaterally, consistent with anaphylaxis.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "34 breaths/min",
          oxygenSaturation: "88%",
          bloodPressure: "70/40 mmHg",
        },
        symptoms: ["Facial swelling", "Wheezing", "Urticaria"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.3 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Impending airway obstruction",
            ETTubeSize: "7.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Severe Asthma Exacerbation in the ER for 29-Year-Old Man",
        name: "Aaron Taylor",
        age: 29,
        active: false,
        gender: "Male",
        weight: "70 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "Asthma",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 29-year-old male with a history of poorly controlled asthma, presented with severe respiratory distress following a viral upper respiratory infection. He was noted to have labored breathing, wheezing on auscultation, and was unable to speak in full sentences. Clinical signs included use of accessory muscles, nasal flaring, and audible wheezing.",
        vitalSigns: {
          heartRate: "135 bpm",
          respiratoryRate: "38 breaths/min",
          oxygenSaturation: "82%",
          bloodPressure: "110/70 mmHg",
        },
        symptoms: ["Wheezing", "Accessory muscle use", "Hypoxemia"],
      },
      management: {
        initialActions: ["Administered 100% oxygen via non-rebreather mask"],
        interventions: {
          nebulizedMedications: {
            medication: "Albuterol and Ipratropium",
            dose: "2.5 mg/0.5 mg",
          },
          intubation: {
            indication: "Impending respiratory failure",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Trauma-Induced Airway Obstruction for 33-Year-Old Man",
        name: "Brian Walker",
        age: 33,
        active: false,
        gender: "Male",
        weight: "82 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 33-year-old male, was brought to the ER after a blunt trauma to the face during a physical altercation. He complained of difficulty breathing, and examination revealed significant facial swelling, bleeding from the nose, and audible stridor, suggesting airway obstruction. Additional findings included trismus and displaced nasal fractures.",
        vitalSigns: {
          heartRate: "115 bpm",
          respiratoryRate: "30 breaths/min",
          oxygenSaturation: "86%",
          bloodPressure: "130/85 mmHg",
        },
        symptoms: ["Facial swelling", "Stridor", "Epistaxis"],
      },
      management: {
        initialActions: ["Positioned airway and applied supplemental oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway compromise due to facial trauma",
            ETTubeSize: "6.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Anaphylaxis-Induced Airway Swelling for 26-Year-Old Man",
        name: "Chris Martinez",
        age: 26,
        active: false,
        gender: "Male",
        weight: "68 kg",
        medicalHistory: {
          knownAllergies: "Bee stings",
          chronicConditions: "Seasonal Allergies",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 26-year-old male, presented to the ER following a bee sting with rapid onset swelling of the lips and tongue, difficulty breathing, and widespread hives. On examination, the patient exhibited hoarseness, inspiratory stridor, and visible swelling of the oropharyngeal structures, consistent with severe anaphylaxis.",
        vitalSigns: {
          heartRate: "140 bpm",
          respiratoryRate: "34 breaths/min",
          oxygenSaturation: "85%",
          bloodPressure: "70/45 mmHg",
        },
        symptoms: ["Lip swelling", "Stridor", "Wheezing"],
      },
      management: {
        initialActions: ["Administered intramuscular epinephrine (0.3 mg)"],
        interventions: {
          nebulizedMedications: { medication: "Salbutamol", dose: "2.5 mg" },
          intubation: {
            indication: "Airway swelling compromising breathing",
            ETTubeSize: "6.0 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Smoke Inhalation Injury for 38-Year-Old Man",
        name: "Jonathan Lee",
        age: 38,
        active: false,
        gender: "Male",
        weight: "76 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 38-year-old male, was rescued from a house fire and brought to the ER with severe shortness of breath and a hoarse voice. Examination revealed soot around the mouth and nose, singed nasal hairs, and audible wheezing. The patient showed signs of hypoxia and carbon monoxide poisoning, with SpO2 readings reduced despite supplemental oxygen.",
        vitalSigns: {
          heartRate: "125 bpm",
          respiratoryRate: "32 breaths/min",
          oxygenSaturation: "88% on room air",
          bloodPressure: "100/60 mmHg",
        },
        symptoms: ["Hoarseness", "Wheezing", "Soot in airways"],
      },
      management: {
        initialActions: ["Administered high-flow oxygen via mask"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Airway edema due to thermal injury",
            ETTubeSize: "7.5 mm (cuffed)",
          },
        },
      },
    },
  },
  {
    scenario: {
      patientDetails: {
        title: "Epiglottitis Management for 27-Year-Old Man",
        name: "Andrew Brown",
        age: 27,
        active: false,
        gender: "Male",
        weight: "72 kg",
        medicalHistory: {
          knownAllergies: "None",
          chronicConditions: "None",
        },
      },
      presentation: {
        chiefComplaint:
          "The patient, a 27-year-old male, presented to the ER with sudden onset sore throat, difficulty swallowing, and severe respiratory distress. Examination revealed stridor, drooling, and a 'tripod' sitting posture. Visual inspection of the oropharynx was avoided due to concerns of exacerbating airway obstruction. Symptoms strongly suggested acute epiglottitis.",
        vitalSigns: {
          heartRate: "130 bpm",
          respiratoryRate: "35 breaths/min",
          oxygenSaturation: "84%",
          bloodPressure: "110/70 mmHg",
        },
        symptoms: ["Stridor", "Drooling", "Difficulty swallowing"],
      },
      management: {
        initialActions: ["Administered humidified oxygen"],
        interventions: {
          nebulizedMedications: {
            medication: "Not applicable",
            dose: "Not applicable",
          },
          intubation: {
            indication: "Severe airway obstruction",
            ETTubeSize: "6.5 mm (cuffed)",
          },
        },
      },
    },
  },
];

export default scenario;
