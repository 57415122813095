import { Padding } from "@mui/icons-material";
import React from "react";

const ProgressBar = ({ value, max }) => {
  // Calculate the width percentage for the filled part
  const percentage = Math.min((value / max) * 100, 100);

  return (
    <div style={styles.container}>
      <div style={{ ...styles.progress, width: `${percentage}%` }} />
    </div>
  );
};

const styles = {
  container: {
    height: "5px",
    backgroundColor: "#e0e0e0",
    borderRadius: "2.5px",
    overflow: "hidden",
    width: "100%",
  },
  progress: {
    height: "100%",
    backgroundColor: "#ff0000", // YouTube-like red color
    transition: "width 0.2s ease-in-out", // Smooth transition for a more dynamic feel
  },
};

export default ProgressBar;
