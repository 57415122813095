import gsap from "gsap";
import beepSound from "../../assets/sounds/beep-sound.mp3";
import { MotionPathPlugin } from "gsap/MotionPathPlugin.js";
import { TextPlugin } from "gsap/TextPlugin";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ManikinSVG from "../../components/manikin";
import { Allotment } from "allotment";
import "allotment/dist/style.css";
import { useDispatch } from "react-redux";
import { MQTTApi } from "../../api";
import { toastSeverity } from "../../components/toastr";
import { socket } from "../../config/socket";
import { hideAlert, showAlert } from "../../store/alert.slice";
import { pathDef, pathStrings } from "./pathdefs";
import PlayLearnTabsComponent from "./play-learn-tabs";
import "./playAndLearn.scss";
import * as dataSets from "./sensorData";
// import logo from "../../assets/images/login/medilogo.png";
import logo from "../../assets/images/login/logo_new.png";
import logo2 from "../../assets/images/login/logo2_new.png";
import start_recording from "../../assets/images/start_recording.png";
import stop_recording from "../../assets/images/stop_record_with_text.png";
import { useTimer } from "react-use-precision-timer";
import { getOrgManikins } from "../../api/manikin.rest";
import { getCurrentDateTime } from "../../components/common/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userSessionAll } from "../../api/mqtt.rest";
import EasySpeech from "easy-speech";
import ProgresBar from "./ProgresBar";
import Tooltip from "@mui/material/Tooltip";
import PrevBtn from "../../components/icons/PrevBtn";
import PlayArrow from "../../components/icons/PlayArrow";
import NextBtn from "../../components/icons/NextBtn";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getErrorCodes } from "../../api/manikin.rest";
import PauseBtn from "../../components/icons/PauseBtn";
import ResizeScreen from "../../components/icons/ResizeScreen";
import ExpandScreen from "../../components/icons/ExpandScreen";
import PlayBtn from "../../components/icons/PlayBtn";
import StartRecording from "../../components/icons/StartRecording";
import { useDarkMode } from "../../hooks/useDarkMode";
import TooltipUi from "../../components/common/TooltipUi";
import Replay from "../../components/icons/Replay";
import Stop from "../../components/icons/Stop";
import RplayGreenIcon from "../../components/icons/RplayGreenIcon";
import { IoStopCircleOutline } from "react-icons/io5";
import Button from "@mui/material/Button";
import { FaExpand } from "react-icons/fa";
import { LuShrink } from "react-icons/lu";

import { BsFillStopFill } from "react-icons/bs";
import { BsRecordCircle } from "react-icons/bs";
import { IoPauseSharp } from "react-icons/io5";
import { IoPlaySharp } from "react-icons/io5";
import { DarkMode } from "@mui/icons-material";
import AssesmentPanel from "../../components/Student-sec/AssesmentPanel";
import { createPortal } from "react-dom";

import ReactDOM from "react-dom";
//register the plugin (just once)
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(TextPlugin);

const SOCKET_EVENTS = {
  MedTrainSensorData: (macID) => `SensorData_${macID}`,
};

const SENSORS = {
  AngleRotation: {}, // for head tilt
  TeethFSR: { path: "common" },
  TracheaReedStart: { path: "trachea" },
  OesophagusReed: { path: "esophagus" },
  TracheaReedInter1: { path: "trachea" },
  TracheaReedInter2: { path: "trachea" },
  TracheaReedEnd: { path: "trachea" },
  TracheaFSR: {},
  RightLungBMP: {},
  LeftLungBMP: {},
  DwellTimeSec: {},
};

const tracheaSensors = Object.keys(SENSORS).reduce((acc, cv) => {
  if (SENSORS[cv]?.path === "trachea") acc.push(cv);
  return acc;
}, []);

const esoSensors = Object.keys(SENSORS).reduce((acc, cv) => {
  if (SENSORS[cv]?.path === "esophagus") acc.push(cv);
  return acc;
}, []);

const sensorPositions = {
  TeethFSR: 0,
  TracheaReedStart: 0.47,
  OesophagusReed: 1,
  TracheaReedInter1: 0.6,
  TracheaReedInter2: 0.87,
  TracheaReedEnd: 0.95,
  RightLungBMP: 1,
  LeftLungBMP: 1,
};

const animDuration = 0.1;
const animDelay = 1;

const getSensorStatus = (sensorCode, currentValue, sensorThreshold) => {
  const _value = parseInt(currentValue);

  // console.log("Checking Values",sensorThreshold)
  const threshold = sensorThreshold[sensorCode];

  switch (sensorCode) {
    case "TeethFSR":
      /**
       * < 4000 = pressure on Teeth
       * > 4000 = good
       * any value after 4000 good */
      return _value > Number(threshold?.value_1)
        ? { key: sensorCode, status: "OK", type: "success", message: "" }
        : {
            key: sensorCode,
            status: "NOT_OK",
            message: "EXCESSIVE PRESSURE ON TEETH",
            type: "error",
          };
    case "TracheaReedStart":
    case "OesophagusReed":
      // rest range =  27000<>29000 = idle
      // < 27000 = pipe is at trachea
      // > 29000 = pipe is at oesophagus
      if (_value >= Number(threshold?.value_1)) {
        return { key: sensorCode, status: "IDLE", type: "idle", message: "" };
      }
      // else if (_value < 27000) {
      //   return { key: sensorCode, status: "OK", type: "success", message: "Correct! Tube enters Trachea" };
      // }
      else if (_value < Number(threshold?.value_2)) {
        return {
          key: sensorCode,
          status: "OK",
          type: sensorCode === "OesophagusReed" ? "error" : "info",
          message:
            sensorCode === "OesophagusReed"
              ? "WRONG ENTRY TO OESOPHAGUS"
              : "TUBE HAS ENTERED TRACHEA",
        };
      }
      break;
    case "TracheaReedInter1":
    case "TracheaReedInter2":
    case "TracheaReedEnd":
      if (_value >= Number(threshold?.value_1))
        return { key: sensorCode, status: "IDLE", type: "idle", message: "" };
      return _value < Number(threshold?.value_2)
        ? {
            key: sensorCode,
            status: "OK",
            type: sensorCode === "TracheaReedEnd" ? "error" : "success",
            message:
              sensorCode === "TracheaReedEnd"
                ? "TUBE IS INSERTED TOO DEEP"
                : "",
          }
        : {
            status: "NOT_OK",
            message: "Incorrect! Tube enters Esophagus",
            type: "error",
          };

    case "TracheaFSR":
      if (_value >= Number(threshold?.value_1))
        return { key: sensorCode, status: "IDLE", type: "idle", message: "" };
      if (_value <= Number(threshold?.value_2)) {
        return {
          key: sensorCode,
          status: "INFLATED",
          message: "BALLOON INFLATED",
          type: "error",
        };
      } else if (
        _value > Number(threshold?.value_2) &&
        _value < Number(threshold?.value_3)
      ) {
        return {
          key: sensorCode,
          status: "MOVEMENT",
          type: "success",
          message: "",
        };
      } else {
        return {
          key: sensorCode,
          status: "OUT_OF_RANGE",
          type: "error",
          message: "Incorrect! Out of range",
        };
      }
    case "RightLungBMP":
      if (_value <= Number(threshold?.value_1))
        return { key: sensorCode, status: "IDLE", type: "idle", message: "" };
      return {
        key: sensorCode,
        status: "INFLATED",
        type: "info",
        message: "RIGHT LUNG INFLATED",
      };
    case "LeftLungBMP":
      if (_value <= Number(threshold?.value_1))
        return { key: sensorCode, status: "IDLE", type: "idle", message: "" };
      return {
        key: sensorCode,
        status: "INFLATED",
        type: "info",
        message: "LEFT LUNG INFLATED",
      };
    case "AngleRotation":
      // console.log("Checking Values",sensorCode,_value, threshold?.value_1, parseInt(Math.abs(_value - Number(threshold?.value_1))))
      // if (parseInt(Math.abs(_value - Number(threshold?.value_1))) > 15)
      return {
        key: sensorCode,
        status: "IDLE",
        type: parseInt(Math.abs(_value - Number(threshold?.value_1))),
        message: "",
      };
    // return {
    //   key: sensorCode,
    //   status: "INFLATED",
    //   type: "error",
    //   message: "LEFT LUNG INFLATED",
    // };
    default:
      return {
        key: sensorCode,
        status: currentValue,
        type: "success",
        message: "",
      };
  }
};

const listingMessageObject = (sensorCode, d) => {
  // console.log("sensorCode",sensorCode)
  const date = getCurrentDateTime(d);
  switch (sensorCode) {
    case "HeadTiltCorrectPos":
      return {
        message: "Head tilted to correct position.",
        type: "info",
        key: 0,
        date,
      };
    case "HeadTiltIncorrectPos":
      return {
        message: "Head Tilted to Incorrect position",
        type: "error",
        key: 0,
        date,
      };
    case "TeethFSR":
      return {
        message: "Excessive Teeth Pressure Detected",
        type: "warning",
        key: 0,
        date,
      };
    case "OesophagusReed":
      return {
        message: "Tube Entered Oesophagus",
        type: "error",
        key: 0,
        date,
      };
    case "TracheaReedStart":
      return {
        message: "Tube is Detected at the start of the trachea",
        type: "info",
        key: 0,
        date,
      };
    case "TracheaReedInter1":
      return {
        message: "Tube moved to the first good position to inflate balloon",
        type: "info",
        key: 0,
        date,
      };
    case "TracheaReedInter2":
      return {
        message:
          "Tube moved to the last good position to inflate the balloon. ",
        type: "info",
        key: 0,
        date,
      };
    case "TracheaReedEnd":
      return {
        message:
          "Trachea reached end of the tube and may have entered the right bronchus.",
        type: "error",
        key: 0,
        date,
      };
    case "TracheaFSR":
      return {
        message: "Balloon Inflated",
        type: "info",
        key: 0,
        date,
      };
    case "LeftRightLungBMP":
      return {
        message: "Left lung above threshold. Right Lung above threshold.",
        type: "info",
        key: 0,
        date,
      };
    case "RightLungBMP":
      return {
        message: "Right lung above threshold. Left Lung below threshold.",
        type: "error",
        key: 0,
        date,
      };
    case "LeftLungBMP":
      return {
        message: "Left lung above threshold. Right Lung below threshold.",
        type: "error",
        key: 0,
        date,
      };
    default:
      return {
        message: "Event Triggered",
        type: "error",
        key: 0,
        date,
      };
  }
};

const addEntryToListingTable = (
  sensorCode,
  listingMessagePrev,
  setListingMessage,
  date,
  lapsedTime
) => {
  const listingMessageInd = listingMessageObject(sensorCode, date);
  if (listingMessageInd?.message !== listingMessagePrev) {
    listingMessagePrev = listingMessageInd?.message;

    setListingMessage((prev) => [
      { ...listingMessageInd, key: prev.length, lapsedTime },
      ...prev,
    ]);
  }
  // console.log("shvhddhdhddddd", sensorCode, listingMessageInd?.message, listingMessagePrev)

  return listingMessagePrev;
};
const findLastActiveSensor = (statusMap) => {
  // for this we need sensors to be in order
  const posWise = {};
  Object.keys(sensorPositions).map((sCode) => {
    posWise[sCode] = statusMap[sCode];
  });

  // return code of last active sensor
  const lastActiveSensorIndex = Object.values(posWise).lastIndexOf("OK");
  return Object.keys(posWise)[lastActiveSensorIndex];
};

// const createBaseInterpolator = (from, to, path) => {
//   return interpolate(pathStrings[path][from], pathStrings[path][to]);
// };

// create base intepolators
// const bi = createBaseInterpolator("z", "f", pathDef.body.label);
// const fi = createBaseInterpolator("z", "f", pathDef.funnel.label);

// convert head tilt angle to transition interpolation point
// const degToPoint = (deg) => {
//   return (1 / 15) * deg;
// };

const beeperPositions = {
  z: { cx: "370", cy: "360" }, // 0 deg
  f: { cx: "320", cy: "400" }, // 15
};

const setBeeperPosition = (headTiltDeg) => {
  gsap.set("#beeper", {
    attr: headTiltDeg === 0 ? beeperPositions.z : beeperPositions.f,
  });
  gsap.set("#point", {
    attr: headTiltDeg === 0 ? beeperPositions.z : beeperPositions.f,
  });
};

// init start of a single head tilt animation
const init = (startPos) => {
  // set default positions for body and funnel
  gsap.set(pathDef.body.selector, {
    attr: { d: pathStrings[pathDef.body.label]["z"] },
  });
  gsap.set(pathDef.funnel.selector, {
    attr: { d: pathStrings[pathDef.funnel.label]["z"] },
  });
  // set default position for arrow path (trachea || esophagus)
  gsap.set("#trachea", {
    attr: {
      d:
        startPos === 15
          ? pathStrings.tracheaPath["f"]
          : pathStrings.tracheaPath["z"],
    },
  });
  gsap.set("#esophagus", {
    attr: {
      d: startPos === 15 ? pathStrings.esoPath["f"] : pathStrings.esoPath["z"],
    },
  });
  // by default hide navigation arrow
  gsap.set("#arrow", {
    attr: { opacity: 0 },
  });
  // set default angle for larry and tube
  gsap.set("#Tube", {
    rotation: startPos * -1,
    x: -(startPos * 3),
    y: startPos * 2,
    transformOrigin: "bottom 40%",
  });
  gsap.set("#Lary", {
    rotation: startPos * -1,
    x: -(startPos * 3),
    y: startPos * 2,
    transformOrigin: "bottom 40%",
  });
  // set degree indicator default rotation
  // gsap.set("#deg_line_y", { rotation: 40, transformOrigin: "bottom" });
  // gsap.set("#deg_text", { text: `0` });
  // hide teeth pressure indicator
  gsap.set("#beacon", { opacity: 0 });
  // set default position for beeper circles
  // this should be inline with default headtilt position
  setBeeperPosition(startPos);
  // start coninuas beacon animation
  gsap.fromTo(
    "#beeper",
    { opacity: 1, scale: 1 },
    {
      opacity: 0,
      scale: 1.2,
      repeat: -1,
      duration: 1,
      transformOrigin: "50% 50%",
    }
  );
};

const rightPanelComponents = {
  ASSESSMENT: "assessment",
  PROGRESS: "progress",
};
let sensorThreshold = null;
let listingMessagePrev = "";
let flashMessagePrev = "";
// Page Component
function PlayAndLearn() {
  const [, setIsConnected] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [manikinData, setManikinData] = useState([]);
  const [macId, setMacID] = useState(null);
  // const [sensorThreshold, setSensorThreshold] = useState([]);

  const [isManikinDataLoading, setIsManikinDataLoading] = useState(false);
  const [deviceId, setDeviceID] = useState(null);
  const [rightPaneComponent, setRightPaneComponent] = useState(
    rightPanelComponents.ASSESSMENT
  );
  const [showManikin, setShowManikin] = useState(false);
  const [socketData, setSocketData] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [listingMessage, setListingMessage] = useState([]);
  const [newListnsMsg, setNewListnsMsg] = useState([]);
  const manikinSvgRef = useRef(null);
  const timeline = useRef(null);
  const lastTimeline = useRef(null);
  const startFrom = useRef(0);
  const targetPath = useRef("#trachea");
  const balloonInflatedShown = useRef(false);
  const [pathCache, setPathCache] = useState({});
  const [time, setTime] = useState();
  const customData = [];
  const [isRunning, setIsRunning] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [selectedSessionId, setSelectedSessionId] = useState("");
  const [sessionDuration, setSessionDuration] = useState(0);
  const [firstThreeSessionArray, setFirstThreeSessionArray] = useState([]);
  // head tilt
  const initialTiltPos = 0;
  const lastTiltPosition = useRef(initialTiltPos);
  const dispatch = useDispatch();
  let socketDataArr = [];
  const [hideSec, setHideSec] = useState(true); //show full screen when hideSec is false
  const [flashingMessage, setFlashingMessage] = useState("");

  const orgId = localStorage.getItem("orgId");
  const userType = localStorage.getItem("userType");
  const timers = useTimer({ delay: 100 });
  const clearTimerRef = useRef();
  const [SwapBtn, setSwapBtn] = useState(false);
  const [params, setParams] = useSearchParams();
  const dId = params.get("mId");
  const uId = params.get("uId");
  const uNm = params.get("uNm");
  const childRef = useRef();
  const [uSessionfilter, setUSessionFilter] = useState("");
  const startTime = useRef();
  const [seekerListMsg, setSeekerListMsg] = useState({});
  const [showListMsg, setShowListMsg] = useState(true);
  const flashMessagePrev = useRef(null);
  const flashMessageTimer = useRef(null);
  const isError = useRef(false);
  const [isOnline, setIsOnline] = useState(
    typeof navigator !== "undefined" && navigator.onLine
  );
  const epocTimer = useRef(null);
  const epocTimer1 = useRef(null);
  const [errorData, setErrorData] = useState();
  const [errorCodeMap, setErrorCodeMap] = useState({});
  const [HideUpperSecs, setHideUpperSecs] = useState(true);
  const [liveSessionState, setLiveSessionState] = useState(false);
  const [switch1, setSwitch1] = useState(false);
  const [selectedSessionName, setSelectedSessionName] = useState("");
  const [replayRunning, setReplayRunning] = useState(false);
  const theme = useDarkMode((state) => state.theme);
  let count = useRef(0);

  // set the initial time
  // useEffect(() => {
  //   epocTimer.current = new Date().getTime();
  // }, []);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [isOnline]);

  useEffect(() => {
    if (isOnline === false) {
      setIsRunning(false);
      clearTimeout(clearTimerRef.current);
      // childRef.current.onStopReplay();
      clearTimerRef.current = null;
      timers.stop();

      reset();
      toast.error("Ur Offline", {
        autoClose: 4000,
      });
      // btnStop()
    }
  }, [isOnline, isRunning]);

  const activeTabIndexSelection = (index) => {
    setActiveTabIndex(index);
  };
  const [isopen, setIsOpen] = useState(false);
  useEffect(() => {
    if (userType === "instructor" && uId) {
      socket.emit("joinSessionRoom", {
        roomName: uId,
      });
    }
  }, [uId, userType]);

  const sessionIdData = (data) => {
    if (data?.status === "running") {
      socket.emit("joinSessionRoom", {
        roomName: data?.session_id,
      });
      setIsRunning(true);
      socket.on("session_data", handleSensorData);
      // console.log("hhhhhhhh", data.session_start, new Date(data.session_start).getTime());
      timers.start(new Date(data.session_start).getTime());
    }
  };

  const getSelectedSessionId = (selectedSessionId1) => {
    setSelectedSessionId(selectedSessionId1);
  };

  useEffect(() => {
    if (!clearTimerRef.current) {
      setNewListnsMsg(listingMessage);
    }
  }, [listingMessage]);
  // console.log("initialTiltPos",initialTiltPos);
  useEffect(() => {
    init(initialTiltPos);
  }, [deviceId, HideUpperSecs]);

  const updateSensorThreshold = (data) => {
    const sensorStatusAll = {};
    data?.forEach((d) => {
      sensorStatusAll[d?.sensor_name] = d;
    });
    // setSensorThreshold(sensorStatusAll);
    sensorThreshold = sensorStatusAll;
  };

  const getSensorStatusAll = (data) => {
    // validate sensor ranges and create a map of it's status
    const sensorStatusAll = {};
    const statusListMap = {};
    const sensorStatusList = [];
    for (let sensorCode in data) {
      const sensorStatus = getSensorStatus(
        sensorCode,
        data[sensorCode],
        sensorThreshold
      ); // setActivityList((prev) => {
      //   const actList = [...prev, sensorStatus];
      //   // return actList.filter((el, index, arr) => el?.message !== arr?.[index + 1]?.message)
      //   const uniqueMessages = [];
      //   return actList.filter(obj => {
      //     if (!uniqueMessages.includes(obj.message)) {
      //       uniqueMessages.push(obj.message);
      //       return true;
      //     }
      //     return false;
      //   });
      // })
      sensorStatusList?.push(sensorStatus);
      sensorStatusAll[sensorCode] = sensorStatus?.status;
      statusListMap[sensorCode] = sensorStatus;
    }

    return {
      statusMap: sensorStatusAll,
      statusList: sensorStatusList,
      statusListMap,
    };
  };

  const btnStart = async () => {
    setLiveSessionState(true);
    isError.current = true;
    if (!macId) {
      window.alert("Please select a device to start simulation.");
      return;
    }
    reset();
    setActivityList([]);
    setListingMessage([]);
    // remove ref to last recording
    lastTimeline.current = null;
    // create a new timeline
    timeline.current = gsap.timeline();
    count.current = 0;
    socket.on("session_data", handleSensorData);
    let resp = await commandMQTTAPI("START");
    //initialize the time when recording has been started
    epocTimer.current = new Date().getTime();
    setIsRunning(true);
    timers.start();
    recursiveTimer();
  };

  const recursiveTimer = () => {
    epocTimer1.current = setTimeout(() => {
      console.log(epocTimer1.current, "inside settimeout");
      if (epocTimer1.current) {
        clearTimeout(epocTimer1.current);
        epocTimer1.current = null;
      }
      const nowEpoc = new Date().getTime();
      console.log(
        "current time :",
        nowEpoc,
        "Initial Time: ",
        epocTimer.current
      );
      let delay = nowEpoc - epocTimer.current;
      console.log(" Difference in ms: ", delay);
      epocTimer.current = nowEpoc;
      if (delay / 1000 > 10) {
        console.log("delay exits 10seconds.");
        toast.error("Error in connecting to manikin", 5000);
        btnStop();
      } else {
        recursiveTimer();
      }
    }, 5000);
    console.log(epocTimer1.current, "outside settimeout");
  };

  const dateDifferenceInMinutes = (dateInitial, dateFinal) =>
    (new Date(dateFinal).getTime() - new Date(dateInitial).getTime()) / 1000;

  const onTabClick = (data, mnId, sessionData) => {
    console.log(data, "Inside Ontabclick", sessionData);
    startTime.current = sessionData?.session_start;
    if (userType === "instructor" && mnId) {
      updateDevice(mnId);
    }
    let endDate = sessionData?.session_end;
    if (data.length) {
      endDate = data[data.length - 1]["RaspTime"];
      if (endDate.slice(-1) !== "Z") {
        endDate = `${endDate}Z`;
      }
    }
    console.log(
      "session start time:",
      sessionData?.session_start,
      "type:",
      typeof sessionData?.session_start
    );
    console.log(endDate, "end date");
    const duration = dateDifferenceInMinutes(
      sessionData?.session_start,
      endDate
    );

    setSessionDuration(duration);
    setListingMessage([]);
    listingMessagePrev = null;
    lastTiltPosition.current = 0;

    data.forEach((data1) => {
      if (data1["RaspTime"].slice(-1) !== "Z") {
        data1["RaspTime"] = `${data1["RaspTime"]}Z`;
      }
      data1["TimeLapsed"] = dateDifferenceInMinutes(
        sessionData?.session_start,
        data1["RaspTime"]
      );
      updateMessage(data1);
    });
  };

  const btnStop = async () => {
    console.log("inside btnstop");
    isError.current = false;
    if (!macId) {
      window.alert("Please select a device to stop simulation.");
      return;
    }
    // timeline.current.pause();
    // store last timeline ref for replay
    // lastTimeline.current = timeline.current;
    socket.off("session_data", handleSensorData);
    setActiveTabIndex(0);
    let resp = await commandMQTTAPI("STOP");
    // set to null so the reference is removed
    timeline.current = null;
    reset();
    init(initialTiltPos);
    getAllManikins();
    setActivityList([]);
    setIsRunning(false);
    // setLiveSessionState(false)
    if (epocTimer1.current) {
      clearTimeout(epocTimer1.current);
      epocTimer1.current = null;
    }
    if (epocTimer.current) {
      clearTimeout(epocTimer.current);
      epocTimer.current = null;
    }
  };

  useEffect(() => {
    init(initialTiltPos);
    getAllManikins();
  }, []);

  const commandMQTTAPI = async (action) => {
    return new Promise(async (resolve, reject) => {
      try {
        let resp = await MQTTApi.commandMQTT(action, deviceId);
        // resolve("started");
        resolve(action);
      } catch (error) {
        reject(error);
      }
    });
  };

  const getAllManikins = async () => {
    setIsManikinDataLoading(true);
    await getOrgManikins(orgId)
      .then((resp) => {
        setManikinData(resp?.data?.data);
      })
      .catch((e) => {
        console.error(e);
        dispatch(
          showAlert({
            message: e?.response?.data?.data || "Something went wrong",
            severity: toastSeverity.ERROR,
          })
        );
        setTimeout(() => dispatch(hideAlert()), 5000);
      })
      .finally(() => {
        setIsManikinDataLoading(false);
      });
  };

  const tiltHead = async (from, to) => {
    if (lastTiltPosition.current === to) return 1;

    // const bie = interpolate(bi(degToPoint(from)), bi(degToPoint(to)));
    // const fie = interpolate(fi(degToPoint(from)), fi(degToPoint(to)));

    // timeline.current.add(function () {
    // console.log("animating body");
    gsap.to(pathDef.body.selector, {
      duration: animDuration,
      attr: {
        d:
          to === 15
            ? pathStrings[pathDef.body.label]["f"]
            : pathStrings[pathDef.body.label]["z"],
      },
      onComplete: function () {
        // Update beeper position after animation completes
        setBeeperPosition(to);
      },
    });
    //   .transition()
    //   .duration(animDuration * 1000)
    //   .attrTween("d", function () {
    //     return bie;
    //   })
    //   .on("end", () => {
    //     // update beeper position
    //     // this should be inline with default headtilt position
    //     setBeeperPosition(to);
    //   });
    // });
    // timeline.current.add(function () {
    // console.log(
    //   "animating funnel",
    //   pathStrings[pathDef.funnel.label]["f"],
    //   pathStrings[pathDef.funnel.label]["z"]
    // );
    gsap.set(pathDef.funnel.selector, {
      // duration: animDuration,
      attr: {
        d:
          to === 15
            ? pathStrings[pathDef.funnel.label]["f"]
            : pathStrings[pathDef.funnel.label]["z"],
      },
    });
    // d3.select(pathDef.funnel.selector)
    //   .transition()
    //   .duration(animDuration * 1000)
    //   .attrTween("d", function () {
    //     return fie;
    //   });
    // }, "<");

    // timeline.current.add(function () {
    // console.log("animating tube+larry+trache+esophagus");
    gsap.to("#Tube", {
      rotation: to * -1,
      x: -(to * 3),
      y: to * 2,
      duration: animDuration,
      transformOrigin: "bottom 40%",
    });
    gsap.to("#trachea", {
      duration: animDuration,
      attr: {
        d:
          to === 15
            ? pathStrings.tracheaPath["f"]
            : pathStrings.tracheaPath["z"],
      },
    });
    gsap.to("#esophagus", {
      duration: animDuration,
      attr: {
        d: to === 15 ? pathStrings.esoPath["f"] : pathStrings.esoPath["z"],
      },
    });
    gsap.to("#Lary", {
      rotation: to * -1,
      x: -(to * 3),
      y: to * 2,
      duration: animDuration,
      transformOrigin: "bottom 40%",
    });
    // }, "<0.4");
    // resetArrowPos();
    // setPathCache({})

    // update last tilt
    lastTiltPosition.current = to;
  };

  const animateArrow = useCallback(
    async (headTilted, targetPos, pathName, setupErr, actItem) => {
      if (!pathName) return;
      setPathCache((prevCache) => {
        if (
          prevCache[pathName] === targetPos + startFrom.current &&
          headTilted === 0
        ) {
          return prevCache; // Return the unchanged cache
        }

        gsap.set("#arrow", {
          opacity: 1,
          motionPath: {
            path: pathName,
            align: pathName,
            autoRotate: -60,
            alignOrigin: [0.7, 0.7],
            start: startFrom.current,
            end: targetPos + startFrom.current,
          },
          onComplete: () => {
            setActivityList((prev) => {
              return [...prev, actItem];
            });
          },
        });

        // Update the cache only if the targetPos is different for the pathName or if the pathName doesn't exist

        return {
          ...prevCache,
          ...(prevCache[pathName] !== targetPos + startFrom.current ||
          !prevCache[pathName]
            ? { [pathName]: targetPos + startFrom.current }
            : {}),
        };
      });
    },
    []
  );

  const showTeethPressure = (actItem) => {
    // console.log("showTeethPressure");
    // beacon position will change based on head tilt angle
    // timeline.current.add(function () {
    gsap.set("#beacon", {
      opacity: 1,
      onComplete: () => {
        setActivityList((prev) => [...prev, actItem]);
      },
    });
    // });
  };

  const resetArrowPos = () => {
    // console.log("resetArrowPos");
    gsap.set("#arrow", {
      opacity: 0,
    });
  };

  const hideTeethPressure = () => {
    // console.log("hideTeethPressure");
    // timeline.current.add(function () {
    gsap.set("#beacon", { opacity: 0 });
    // });
  };

  const isCuffInflated = (statusMap) => {
    return statusMap["TracheaFSR"] === "INFLATED";
  };

  const isLungInflated = (statusMap, which) => {
    const sensorName = which === "right" ? "RightLungBMP" : "LeftLungBMP";
    return statusMap[sensorName] === "INFLATED";
  };

  const inflateLungs = async (which, actItem) => {
    gsap.set(`#${which}_lung`, {
      scale: 1.1,
      onComplete: () => {
        setActivityList((prev) => [...prev, actItem]);
      },
    });
    // timeline.current.to(
    //   `#${which}_lung`,
    //   {
    //     scale: 1.1,
    //     duration: animDuration,
    //     ease: "power1.inOut",
    //     onComplete: () => {
    //       setActivityList((prev) => [...prev, actItem])
    //     }
    //   },
    //   "<"
    // );
  };

  const deflateLungs = async (which, actItem) => {
    gsap.set(`#${which}_lung`, {
      scale: 1,
      onComplete: () => {
        setActivityList((prev) => [...prev, actItem]);
      },
    });
    // timeline.current.to(
    //   `#${which}_lung`,
    //   {
    //     scale: 1,
    //     duration: animDuration,
    //     ease: "power1.inOut",
    //     onComplete: () => {
    //       setActivityList((prev) => [...prev, actItem])
    //     }
    //   },
    //   "<"
    // );
  };

  const inflateCircle = async (actItem) => {
    gsap.to("#baloon", {
      scale: 2,
      ease: "linear",
      transformOrigin: "50% 50%",
      onComplete: () => {
        setActivityList((prev) => [...prev, actItem]);
      },
    });
  };

  const resetSetupErrorText = () => {
    if (flashMessageTimer.current) {
      clearTimeout(flashMessageTimer.current);
      flashMessageTimer.current = null;
    }
    const errTextElem = document.querySelector("#err_text");
    gsap.set(errTextElem, { textContent: `` });

    if (isRunning === false) {
      const errTextBgElem = document.querySelector("#err_bg");
      errTextBgElem.style.fill = theme === "dark" ? "#181717" : "#ffffff";
    }

    const errTextBgElem = document.querySelector("#err_bg");
    errTextBgElem.style.fill = theme === "dark" ? "#181717" : "#ffffff";
  };

  const deflateCircle = async (actItem) => {
    gsap.set("#baloon", {
      scale: 1,
      // ease: "linear",
      onComplete: () => {
        setActivityList((prev) => [...prev, actItem]);
      },
    });
  };

  const reset = () => {
    startFrom.current = 0;
    listingMessagePrev = null;
    flashMessagePrev.current = null;
    lastTiltPosition.current = 0;
    resetSetupErrorText();
    resetArrowPos();
    balloonInflatedShown.current = false;
  };

  const handleActivityListChange = (data) => {
    // console.log(data);
    // setActivityList((prev) => {
    //   const actList = [...prev, data];
    //   // return actList.filter((el, index, arr) => el?.message !== arr?.[index + 1]?.message)
    //   const uniqueMessages = [];
    //   return actList.filter((obj) => {
    //     if (!uniqueMessages?.includes(obj?.message)) {
    //       uniqueMessages.push(obj.message);
    //       return true;
    //     }
    //     return false;
    //   });
    // });
  };

  const updateMessage = (
    data,
    activeSensorCode,
    statusMap1,
    angleRotation,
    date,
    lapsedTime
  ) => {
    if (data) {
      const { statusMap, statusList, statusListMap } = getSensorStatusAll(data);
      const activeSensorCode1 = findLastActiveSensor(statusMap);
      activeSensorCode = activeSensorCode1;
      statusMap1 = statusMap;
      angleRotation = statusListMap["AngleRotation"]?.type;
      date = data.RaspTime;
      lapsedTime = data["TimeLapsed"];
    }
    if (esoSensors.includes(activeSensorCode)) {
      listingMessagePrev = addEntryToListingTable(
        "OesophagusReed",
        listingMessagePrev,
        setListingMessage,
        date,
        lapsedTime
      );
    } else if (tracheaSensors.includes(activeSensorCode)) {
      listingMessagePrev = addEntryToListingTable(
        activeSensorCode,
        listingMessagePrev,
        setListingMessage,
        date,
        lapsedTime
      );
    }
    if (statusMap1["TeethFSR"] === "NOT_OK") {
      listingMessagePrev = addEntryToListingTable(
        "TeethFSR",
        listingMessagePrev,
        setListingMessage,
        date,
        lapsedTime
      );
    }
    if (angleRotation) {
      const targetDeg = parseFloat(angleRotation) <= 15 ? 0 : 15;

      if (lastTiltPosition.current !== targetDeg) {
        if (data) {
          lastTiltPosition.current = targetDeg;
        }
        const listingMessageInd =
          targetDeg === 15
            ? listingMessageObject("HeadTiltCorrectPos", date)
            : listingMessageObject("HeadTiltIncorrectPos", date);
        if (listingMessagePrev !== listingMessageInd.message) {
          listingMessagePrev = listingMessageInd.message;

          setListingMessage((prev) => [
            { ...listingMessageInd, key: prev.length, lapsedTime: lapsedTime },
            ...prev,
          ]);
        }
      }
    }
    if (isCuffInflated(statusMap1)) {
      listingMessagePrev = addEntryToListingTable(
        "TracheaFSR",
        listingMessagePrev,
        setListingMessage,
        date
      );
    }
    const isRightLungsInflated = isLungInflated(statusMap1, "right");
    const isLeftLungsInflated = isLungInflated(statusMap1, "left");
    if (isRightLungsInflated && isLeftLungsInflated) {
      listingMessagePrev = addEntryToListingTable(
        "LeftRightLungBMP",
        listingMessagePrev,
        setListingMessage,
        date
      );
    } else if (isRightLungsInflated && !isLeftLungsInflated) {
      listingMessagePrev = addEntryToListingTable(
        "RightLungBMP",
        listingMessagePrev,
        setListingMessage,
        date
      );
    } else if (!isRightLungsInflated && isLeftLungsInflated) {
      listingMessagePrev = addEntryToListingTable(
        "LeftLungBMP",
        listingMessagePrev,
        setListingMessage,
        date
      );
    }
  };

  const textToSpeech = async (text) => {
    try {
      // const textWithoutHtml = sessionData?.sessionDebriefing?.debriefing?.replace(/<[^>]+>/g, '');
      // console.log("llllllllkkkkkkk ",EasySpeech.status()?.speechSynthesis)
      await EasySpeech.init();

      const voices = EasySpeech.voices();
      const voice = voices[2];

      const utterance = new SpeechSynthesisUtterance(text);
      utterance.voice = voice;

      utterance.onerror = function (event) {
        console.error("Speech synthesis error:", event);
      };

      utterance.onend = function () {
        // setBtnHide(false);
        // EasySpeech.cancel();
      };
      if (EasySpeech.status()?.speechSynthesis?.speaking) {
        EasySpeech.cancel();
      }

      await EasySpeech.speak(utterance);
    } catch (error) {
      console.error("An error occurred during speech synthesis:", error);
    }
  };

  const getErrorCodeData = async () => {
    const data = {};
    try {
      const res = await getErrorCodes(data);
      setErrorData(res.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getErrorCodeData();
  }, []);

  useEffect(() => {
    errorData?.forEach((erCode, i) => {
      if (erCode?.code && erCode?.description) {
        setErrorCodeMap((prevErrorCodeMap) => ({
          ...prevErrorCodeMap,
          [errorData[i].code]: errorData[i].description,
        }));
      }
    });
  }, [errorData]);

  const animate = (data) => {
    // targetPath.current = null;
    const errorCodeArray = data?.SetupError.split(",");
    const errorCode = data?.SetupError;
    if (isError.current) {
      if (errorCode) {
        btnStop();
        errorCodeArray?.forEach((val) => {
          if (errorCodeMap[val] !== undefined) {
            toast.error(errorCodeMap[val], {
              autoClose: 1000,
            });
          }
        });
      }
    }
    setActivityList([]);
    socketDataArr.push(data);
    setSocketData((prev) => {
      let newDataArr = prev;
      newDataArr.push(data);
      return newDataArr;
    });
    const { statusMap, statusList, statusListMap } = getSensorStatusAll(data);

    let capturingFlashingMessage = "";
    let captureFlashingMessageType = "info";

    // from given data find last active sensor
    const activeSensorCode = findLastActiveSensor(statusMap);
    updateMessage(
      null,
      activeSensorCode,
      statusMap,
      statusListMap["AngleRotation"]?.type,
      data?.RaspTime,
      0
    );
    // change path based on sensor
    // targetPath.current = null;
    if (esoSensors.includes(activeSensorCode)) {
      // console.log("1");
      targetPath.current = "#esophagus";
      handleActivityListChange(
        statusList.find((e) => e.key.includes("Oesophagus"))
      );
      capturingFlashingMessage = statusListMap["OesophagusReed"]?.message;
      captureFlashingMessageType = statusListMap["OesophagusReed"]?.type;

      // inflateCircle(statusList?.find((elem) => elem?.key === "TracheaFSR"));
    } else if (tracheaSensors.includes(activeSensorCode)) {
      // if (statusMap["TracheaReedStart"] === "OK") {
      //   targetPath.current = "#trachea";
      // } else if (statusMap["TracheaReedEnd"] === "OK") {
      //   targetPath.current = "#trachea";
      // }

      targetPath.current = "#trachea";
      capturingFlashingMessage = statusListMap[activeSensorCode]?.message;
      captureFlashingMessageType = statusListMap[activeSensorCode]?.type;

      // else{
      // targetPath.current = "#trachea";

      // }
      // handleActivityListChange(
      //   statusList.find((e) => e.key.includes("Trachea"))
      // );
    } else if (data?.AngleRotation === 0) {
      // For each iteration setting path to null
      // init(initialTiltPos);
      // getAllManikins();
      targetPath.current = null;
      setPathCache({});
      gsap.set(pathDef.body.selector, {
        attr: { d: pathStrings[pathDef.body.label]["z"] },
      });
      gsap.set(pathDef.funnel.selector, {
        attr: { d: pathStrings[pathDef.funnel.label]["z"] },
      });
      resetArrowPos();
    }
    const targetOffset = sensorPositions[activeSensorCode];

    if (isRunning && targetOffset !== 0) {
      count.current = 1;
    }

    if (count.current >= 1) {
      btnStop();
      toast.error("error");
    }
    // console.log("Checking Target Path,offset", targetOffset,
    // targetPath.current)
    if (statusMap["TeethFSR"] === "NOT_OK") {
      captureFlashingMessageType = statusListMap["TeethFSR"]?.type;
      capturingFlashingMessage = statusListMap["TeethFSR"]?.message;
      setFlashingMessage(statusListMap["TeethFSR"]?.message);

      showTeethPressure(statusList?.find((elem) => elem?.key === "TeethFSR"));
      // handleActivityListChange(statusList.find((e) => e.key.includes("TeethFSR")))
    } else {
      hideTeethPressure();
      // handleActivityListChange(statusList.find((e) => e.key.includes("TeethFSR")))
    }

    // animate headtilt
    let headTilted = 0;
    if (data?.AngleRotation) {
      const targetDeg =
        parseFloat(statusListMap["AngleRotation"]?.type) <= 15 ? 0 : 15;
      headTilted = tiltHead(lastTiltPosition.current, targetDeg);
      // console.log("inside then in headtile", targetDeg, headTilted, targetOffset)
      /* .then(() => {
      console.log("inside then in headtile")
        handleActivityListChange(statusList.find((e) => e.key.includes("HeadTilt")))
      }) */
      // preview angle
      // get current angle
      // const degTextElem = document.querySelector("#deg_text");
      // gsap.fromTo(
      //   degTextElem,
      //   { text: `${degTextElem?.innerHTML}` },
      //   { text: `${data?.AngleRotation}`, duration: animDuration }
      // );
    }

    // console.log("statusListMap",statusMap,statusList,activeSensorCode,statusListMap)

    // Updating Previous Text Value
    // prevSetupErrText = flashingMessage;

    // animate arrow
    // if (
    //   esoSensors.includes(activeSensorCode) ||
    //   tracheaSensors.includes(activeSensorCode)
    // ) {

    // console.log("sssssssssssssssssdddddd", targetPath.current, targetOffset);
    animateArrow(
      headTilted,
      targetOffset,
      targetPath.current,
      data?.SetupError,
      statusList?.find((elem) => elem?.key === "TracheaReedStart")
    ).then(() => {});
    // }

    // check wheather cuff is inflated by checking TracheaFSR value
    if (isCuffInflated(statusMap)) {
      captureFlashingMessageType = statusListMap["TracheaFSR"]?.type;
      capturingFlashingMessage = statusListMap["TracheaFSR"]?.message;

      inflateCircle(statusList?.find((elem) => elem?.key === "TracheaFSR"));
      // handleActivityListChange(statusList.find((e) => e.key.includes("TracheaFSR")))
    } else {
      // capturingFlashingMessage = ""
      deflateCircle(statusList?.find((elem) => elem?.key === "TracheaFSR"));
      // handleActivityListChange(statusList.find((e) => e.key.includes("TracheaFSR")))
    }

    // Custom LungsInflate Flashing Messages
    const isRightLungsInflated = isLungInflated(statusMap, "right");
    const isLeftLungsInflated = isLungInflated(statusMap, "left");
    if (isRightLungsInflated && isLeftLungsInflated) {
      captureFlashingMessageType = "error";
      capturingFlashingMessage = "BOTH LUNGS INFLATED";
    } else if (isRightLungsInflated && !isLeftLungsInflated) {
      captureFlashingMessageType = statusListMap["RightLungBMP"]?.type;
      capturingFlashingMessage = statusListMap["RightLungBMP"]?.message;
    } else if (!isRightLungsInflated && isLeftLungsInflated) {
      captureFlashingMessageType = statusListMap["LeftLungBMP"]?.type;
      capturingFlashingMessage = statusListMap["LeftLungBMP"]?.message;
    }

    // check wheather right lung is inflated
    if (isRightLungsInflated) {
      inflateLungs(
        "right",
        statusList?.find((elem) => elem?.key === "RightLungBMP")
      );
      // handleActivityListChange(statusList.find((e) => e.key.includes("RightLungBMP")))
    } else {
      deflateLungs(
        "right",
        statusList?.find((elem) => elem?.key === "RightLungBMP")
      );
    }

    // check wheather left lung is inflated
    if (isLeftLungsInflated) {
      inflateLungs(
        "left",
        statusList?.find((elem) => elem?.key === "LeftLungBMP")
      );
    } else {
      deflateLungs(
        "left",
        statusList?.find((elem) => elem?.key === "LeftLungBMP")
      );
    }

    // Printing Flashing Message
    if (
      flashMessagePrev.current !== capturingFlashingMessage &&
      capturingFlashingMessage &&
      ((!balloonInflatedShown.current &&
        capturingFlashingMessage === "BALLOON INFLATED") ||
        capturingFlashingMessage != "BALLOON INFLATED")
    ) {
      // console.log();
      const errTextElem = document.querySelector("#err_text");
      const errTextBgElem = document.querySelector("#err_bg");
      errTextBgElem.style.fill = "#C00000";
      gsap.to("#Error_Message", {
        fill: captureFlashingMessageType === "error" ? "#fff" : "#F0D000",
      });
      // Change fill color of the parent <g> element
      gsap.to("#Error_Message", {
        fill: captureFlashingMessageType === "error" ? "#fff" : "#F0D000",
      });

      if (capturingFlashingMessage === "BALLOON INFLATED") {
        if (!balloonInflatedShown.current) {
          balloonInflatedShown.current = true;
        } else {
          return;
        }
      }
      errTextElem.textContent = capturingFlashingMessage;
      if (captureFlashingMessageType === "error") {
        textToSpeech(capturingFlashingMessage);
      }
      if (flashMessageTimer.current) {
        clearTimeout(flashMessageTimer.current);
        flashMessageTimer.current = null;
      }

      flashMessageTimer.current = setTimeout(() => {
        resetSetupErrorText();
      }, 3000);

      flashMessagePrev.current = capturingFlashingMessage;

      // Apply blinking effect using GSAP
      // const tl = gsap.timeline({ repeat: 1, yoyo: true });
      // tl.to(errTextElem, { opacity: 0, duration: 0.15 }).to(errTextElem, {
      //   opacity: 1,
      //   onComplete: () => {
      //     errTextBgElem.style.fill = "black"
      //   },
      // })
      // console.log("kkkkkklllllll",prevSetupErrText,capturingFlashingMessage);
      // Play the sound only once the text changes

      // if (prevSetupErrText !== capturingFlashingMessage && capturingFlashingMessage !== "BALLOON INFLATED") {

      // }
      // commented beep audio
      // if (prevSetupErrText === "BALLOON INFLATED") {
      //   new Audio(beepSound).stop();
      // }
    }
    // else {
    //   resetSetupErrorText();
    // }
  };
  let index = 0;
  let timeOutId;
  const CustomRecursive = () => {
    animate(customData[index]);

    index++;

    clearTimerRef.current = setTimeout(() => {
      if (index >= customData?.length - 1) {
        clearTimeout(clearTimerRef.current);
        childRef.current.onStopReplay();
        clearTimerRef.current = null;
        timers.stop();

        reset();
        init(initialTiltPos);
        return;
      } else {
        CustomRecursive();
      }
    }, customData[index]?.customTime - customData[index - 1]?.customTime);
  };

  const simulateSession = (datasetData) => {
    if (clearTimerRef.current) {
      setListingMessage(newListnsMsg);
      clearTimeout(clearTimerRef.current);
      clearTimerRef.current = null;
      timers.stop();

      reset();
      init(initialTiltPos);
    } else {
      setListingMessage([]);
      for (let i = 0; i <= datasetData.length - 1; i++) {
        const date = new Date(datasetData[i].RaspTime);
        datasetData[i].customTime = date.getTime();
      }

      datasetData = datasetData.sort(function (x, y) {
        return x.customTime - y.customTime;
      });

      customData.push(...datasetData);

      CustomRecursive();

      setActivityList([]);
      if (timeline.current) {
        timeline.current.kill();
        startFrom.current = 0;
      }
      timers.start();
    }
  };
  const stopAnimation = () => {
    clearTimeout(timeOutId);
  };

  const handleSensorData = async (data) => {
    console.log(" called!!");
    console.log(data);
    try {
      // setTimeout(() => {
      epocTimer.current = new Date().getTime();
      // }, 5000);
      const pData = await JSON.parse(data?.data);
      animate(pData);
    } catch (error) {
      console.log("Checking Errors for Handle Sensor Data", error);
    }
  };
  //   ,
  //   [sensorThreshold]
  // );
  useEffect(() => {
    if (time && !timers.isRunning) {
      timers.start();
    } else if (!time) {
      timers.stop();
    }
  }, [time]);

  const btnReplay = () => {
    if (!lastTimeline.current) {
      window.alert("Last recording is not available!");
      return;
    }
    lastTimeline.current.restart();
  };
  const replaySessionLogs = () => {
    childRef.current.onStopReplay();
    // setHideSec((prev) => !prev);
  };

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    function onError(err) {
      console.log(err);
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", onError);
    socket.on("session_close", onSessionClosed);

    socket.connect();

    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", onError);
      socket.off("session_data", handleSensorData);
      socket.off("session_close", onSessionClosed);
      socket.off("session_started", onSessionStart);
    };
  }, [socket]);

  const onSessionClosed = (data) => {
    socket.off("session_data", handleSensorData);
    if (data?.sessionId) {
      reset();
      init(initialTiltPos);
      setIsRunning(false);
    }
  };
  useEffect(() => {
    socket.on("session_started", onSessionStart);
    return () => {
      socket.off("session_started", onSessionStart);
    };
  }, [socket, uSessionfilter]);

  const onSessionStart = (data) => {
    if (uSessionfilter) {
      childRef.current.refreshPage();
    }
  };

  const formatTime = (time) => {
    const seconds = parseInt(time / 1000);
    const ms = time % 1000;

    return ` ${seconds.toString().padStart(2, "0")} sec. ${ms
      .toString()
      .padStart(3, "0")} ms.`;
  };

  const updateDevice = (devID) => {
    setShowManikin(true);
    setDeviceID(devID);
    // find mac id

    const device = manikinData.find((d) => d?.device_id === devID);
    if (device) {
      setMacID(device?.mac_id);
      updateSensorThreshold(device?.sensorThreshold);
    }
  };

  // useEffect(() => {
  //   if (devId && manikinData.length > 0) {
  //     updateDevice(devId)
  //     socket.on("session_data", handleSensorData);
  //   }
  // }, [manikinData,devId])

  useEffect(() => {
    if (dId && manikinData.length > 0) {
      updateDevice(dId);
    }
  }, [dId, manikinData]);

  function stringToJson(string) {
    string = string.trim();

    // Split the string into an array of individual transformations
    let transformations = string.split(" ");

    // Create an empty object to store the transformation properties
    let json = {};

    // Iterate over each transformation in the array
    for (let transformation of transformations) {
      // Extract the property and value from each transformation
      let [property, value] = transformation.split("(");

      // Remove the closing parentheses from the value
      value = value.replace(")", "");

      // Store the property and value in the JSON object
      json[property] = value;
    }

    return json;
  }

  function replaceNumbersWithString(string, data) {
    // Iterate over each key in the data object
    let updatedString = string;
    for (let key in data) {
      // Create a regular expression to match the numbers associated with the key
      let regex = new RegExp(`(${key}\\()[-+]?\\d+(\\.\\d+)?(\\D|$)`, "g");

      // Replace the matched numbers with the corresponding value from the data object
      updatedString = updatedString
        .replace(regex, `$1${data[key]}$3`)
        .replaceAll("pxpx", "px");
    }

    return updatedString;
  }

  const handleZoom = (type) => {
    let manikinTransform =
      manikinSvgRef?.current?.style?.transform || "scale(1)";
    const currentTransformObj = !!manikinTransform
      ? stringToJson(manikinTransform)
      : { scale: "" };
    if (!currentTransformObj?.hasOwnProperty("scale")) {
      currentTransformObj["scale"] = 1;
      manikinTransform = `${manikinTransform} scale(1)`;
    }
    if (type === "+") {
      // zoom in
      const svgScale =
        !!currentTransformObj && Number(currentTransformObj?.scale) < 5
          ? Number(currentTransformObj?.scale) + 0.1
          : Number("5");
      const dat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        scale: svgScale,
      });
      manikinSvgRef.current.style.transform = dat;
    } else if (type === "-") {
      // zoom out
      const svgScale =
        !!currentTransformObj && Number(currentTransformObj?.scale) > 0.1
          ? Number(currentTransformObj?.scale) - 0.1
          : Number("0.1");
      const dat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        scale: svgScale,
      });
      manikinSvgRef.current.style.transform = dat;
    }
  };

  const handleTranslateX = (type) => {
    let manikinTransform =
      manikinSvgRef?.current?.style?.transform || "translateX(0px)";
    const currentTransformObj = !!manikinTransform
      ? stringToJson(manikinTransform)
      : { translateX: "" };
    if (!currentTransformObj?.hasOwnProperty("translateX")) {
      currentTransformObj["translateX"] = "0px";
      manikinTransform = `${manikinTransform} translateX(0px)`;
    }
    const translateXVal = currentTransformObj?.["translateX"]?.replace(
      "px",
      ""
    );
    if (type === "+") {
      const svgTranslateX = Number(translateXVal) + 20;
      const updatedDat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        translateX: svgTranslateX,
      });
      manikinSvgRef.current.style.transform = updatedDat;
    } else if (type === "-") {
      const svgTranslateX = Number(translateXVal) - 20;
      const updatedDat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        translateX: svgTranslateX,
      });
      manikinSvgRef.current.style.transform = updatedDat;
    }
  };

  const handleTranslateY = (type) => {
    let manikinTransform =
      manikinSvgRef?.current?.style?.transform || "translateY(0px)";
    const currentTransformObj = !!manikinTransform
      ? stringToJson(manikinTransform)
      : { translateY: "" };
    if (!currentTransformObj?.hasOwnProperty("translateY")) {
      currentTransformObj["translateY"] = "0px";
      manikinTransform = `${manikinTransform} translateY(0px)`;
    }
    const translateYVal = currentTransformObj?.["translateY"]?.replace(
      "px",
      ""
    );
    if (type === "+") {
      const svgTranslateY = Number(translateYVal) + 20;
      const updatedDat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        translateY: svgTranslateY,
      });
      manikinSvgRef.current.style.transform = updatedDat;
    } else if (type === "-") {
      const svgTranslateY = Number(translateYVal) - 20;
      const updatedDat = replaceNumbersWithString(manikinTransform, {
        ...currentTransformObj,
        translateY: svgTranslateY,
      });
      manikinSvgRef.current.style.transform = updatedDat;
    }
  };

  const handleResetTransform = () => {
    manikinSvgRef.current.style.transform = "";
  };

  const playSound = () => {
    new Audio(beepSound).play();
  };

  const timer = (running) => {
    setTime(running);
  };

  const hideBottomSection = (hideSection) => {
    setHideSec(hideSection);
  };
  const HideUpperSecsAction = (state) => {
    setHideUpperSecs((prevState) => {
      return !prevState;
    });
  };

  const handleSessionFilterClick = (filter) => {
    if (userType === "instructor") {
      setUSessionFilter(filter);
    }
  };

  const onClickSeeker = (t, v, index, data) => {
    // console.log("ghfghjfgjgjg111111111", v, data, index);
    setSeekerListMsg(v);
    // console.log("dfghfddjfd", new Date().getTime());
    timers.start(new Date(new Date().getTime() - t * 1000));
    setShowListMsg(false);
    data?.map((d, i) => {
      // console.log("111111111", d);
      if (i === index) {
        // console.log("22222222", d);
        // console.log("heeeeeeee", data?.slice(i, -1));
        // setListingMessage(data?.slice(i,-1))
      } else {
        // console.log("nooooooot coming");
      }
    });
  };

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }} className="flex relative">
        <Allotment
          vertical
          className="h-100 play-and-learn-container"
          onVisibleChange={(_index, value) => {
            setHideSec(value);
          }}
        >
          {HideUpperSecs && (
            <Allotment className="h-100 left-pane-allotment">
              {/* upper section  */}

              <Allotment.Pane
                className=" top-left-pane p-0"
                preferredSize="80%"
              >
                <div className="relative item video-player h-full flex-column gap-2 manikin-svg-container w-full">
                  <div className="relative flex w-[100%] h-[100%] bg-BgGray">
                    {deviceId === null ? (
                      <div className="relative w-[100%] h-[100%] flex justify-center items-center text-[#787878] font-normal font-xs">
                        {/* logo starts  */}
                        <div className="z-40 w-max h-max flex  items-center p-2 gap-1 absolute top-0 left-0">
                          <a href="http://medtrain.ai" target="_medtrainai">
                            <div className="manikin-cont w-[100px] h-[20px]">
                              {localStorage.theme === "dark" ? (
                                <img src={logo2} alt="logo" className="" />
                              ) : (
                                <img src={logo} alt="logo" className="" />
                              )}
                            </div>
                          </a>
                          {/* <div className="w-max text-xl font-normal text-[#787878]">
                            MedTrain AI
                          </div> */}
                        </div>
                        {/* logo ends */}
                        {userType === "instructor" ? (
                          <div className=""> Select a Session to start</div>
                        ) : (
                          <div className="text-textColor text-2xl">
                            {" "}
                            Select a Manikin to start
                          </div>
                        )}
                      </div>
                    ) : (
                      <>
                        <div className="relative svg-container bg-BgGray w-full flex flex-col justify-center items-center my-2">
                          <div className="absolute z-40 top-0 left-0 w-max h-max  items-center p-2 gap-1">
                            <a href="http://medtrain.ai" target="_medtrainai">
                              <div className="manikin-cont w-[100px] h-[20px]">
                                {localStorage.theme === "dark" ? (
                                  <img src={logo2} alt="logo" className="" />
                                ) : (
                                  <img src={logo} alt="logo" className="" />
                                )}
                              </div>
                            </a>
                          </div>
                          <ManikinSVG ref={manikinSvgRef} />
                          <div className="absolute left-2 bottom-1">
                            {isRunning ? (
                              <Tooltip title="Stop recording..">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    btnStop();
                                    replaySessionLogs();
                                  }}
                                >
                                  <BsFillStopFill
                                    size={30}
                                    className="text-red-600"
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Start recording..">
                                <div className="cursor-pointer">
                                  <BsRecordCircle
                                    size={30}
                                    className="text-red-600"
                                    onClick={() => setIsOpen(true)}
                                  />
                                  {createPortal(
                                    <AssesmentPanel
                                      open={isopen}
                                      onClose={() => setIsOpen(false)}
                                      record={() => {
                                        btnStart();
                                        setIsOpen(false);
                                      }}
                                    ></AssesmentPanel>,
                                    document.body
                                  )}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                          <div className="record-play-session-progress-timeLap w-full flex justify-center items-center gap-2">
                            {/* progress bar inside*/}
                            {isRunning === false &&
                              listingMessage.length > 0 && (
                                <div className="bg-slate-400 bg-opacity-20 w-[60%] justify-between flex px-2 rounded-lg">
                                  <div className="record-play-session flex justify-start items-center pl-2 ">
                                    {listingMessage.length > 0 &&
                                      (!replayRunning ? (
                                        <Tooltip title="Start Replay">
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              replaySessionLogs();
                                            }}
                                          >
                                            <IoPlaySharp
                                              size={30}
                                              className="text-textColor"
                                            />
                                          </div>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title="Stop Replay">
                                          <div
                                            className="cursor-pointer"
                                            onClick={() => {
                                              replaySessionLogs();
                                            }}
                                          >
                                            <IoPauseSharp
                                              size={30}
                                              className="text-textColor"
                                            />
                                          </div>
                                        </Tooltip>
                                      ))}
                                  </div>

                                  {isRunning === false && (
                                    <div className="w-[85%] my-auto">
                                      <ProgresBar
                                        sessionDuration={sessionDuration}
                                        currentTime={timers.getElapsedStartedTime()}
                                        listingMessage={newListnsMsg}
                                        onClickSeeker={onClickSeeker}
                                      />
                                    </div>
                                  )}
                                  <div className="d-flex gap-2 clock w-max ">
                                    {deviceId === null ? "" : <></>}
                                  </div>
                                </div>
                              )}
                            {/* progress bar inside*/}
                            <div className="text-textColor font-normal absolute right-2 bottom-1">
                              {isRunning === false &&
                              listingMessage.length >= 0 ? (
                                selectedSessionName
                              ) : (
                                <Tooltip title="Time" className="">
                                  <div className="timer">
                                    <p className="time mb-0">
                                      {formatTime(
                                        timers.getElapsedStartedTime()
                                      )}
                                    </p>
                                  </div>
                                </Tooltip>
                              )}
                            </div>

                            {hideSec ? (
                              <div className="shrink-icon absolute right-0 top-0 p-2">
                                <Tooltip title="Expand Screen">
                                  <div
                                    onClick={() => setHideSec(false)}
                                    className="for-expand cursor-pointer"
                                  >
                                    <FaExpand
                                      size={30}
                                      className="text-textColor"
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="shrink-icon absolute right-0 top-0 p-2">
                                <Tooltip title="Shrink">
                                  <div
                                    onClick={() => setHideSec(true)}
                                    className="cursor-pointer"
                                  >
                                    <LuShrink
                                      size={30}
                                      className="text-textColor"
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            )}
                          </div>
                          {/* progress bar section ends   */}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Allotment.Pane>

              {HideUpperSecs && (
                <Allotment.Pane visible={hideSec}>
                  {userType === "instructor" ? (
                    <div className=" w-full h-[100%] bg-background p-4 z-50 flex flex-col  absolute right-0 ">
                      <div className="text-gray-500 pt-2 pb-3 font-normal">
                        <p className="text-headingTxt2 font-semibold text-xl">
                          Student Dashboard
                        </p>
                        <p className="text-textColor">{uNm}</p>
                      </div>
                      <div className="text-textColor text-sm flex flex-col gap-3">
                        <div
                          className={`cursor-pointer ${
                            uSessionfilter === "ALL"
                              ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                              : ""
                          }`}
                          onClick={() => handleSessionFilterClick("ALL")}
                        >
                          Show All Sessions
                        </div>
                        <div
                          className={`cursor-pointer ${
                            uSessionfilter === "PRAC"
                              ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                              : ""
                          }`}
                          onClick={() => handleSessionFilterClick("PRAC")}
                        >
                          Show Only Practices
                        </div>
                        <div
                          className={`cursor-pointer ${
                            uSessionfilter === "10PRAC"
                              ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                              : ""
                          }`}
                          onClick={() => handleSessionFilterClick("10PRAC")}
                        >
                          Show Last 10 Practices
                        </div>
                        <div
                          className={`cursor-pointer ${
                            uSessionfilter === "ASSGN"
                              ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                              : ""
                          }`}
                          onClick={() => handleSessionFilterClick("ASSGN")}
                        >
                          Show Only Assignments
                        </div>
                        <div
                          className={`cursor-pointer ${
                            uSessionfilter === "10ASSGN"
                              ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                              : ""
                          }`}
                          onClick={() => handleSessionFilterClick("10ASSGN")}
                        >
                          {" "}
                          Show Last 10 Assignmets
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" w-full h-[100%] bg-bgManikinList  px-4 pb-2 z-50 flex flex-col justify-between absolute right-0 border-l-2 border-[var(--borderLeft)]">
                      <div>
                        <div className=" text-headingText pt-2 pb-3 font-semibold">
                          {!showManikin ? "Select" : "Manikin"}
                        </div>
                        <div className=" text-textColor flex flex-col gap-6 overflow-y-scroll">
                          {!!manikinData &&
                          Array.isArray(manikinData) &&
                          manikinData?.length > 0 ? (
                            manikinData?.map((item) => {
                              return (
                                <div
                                  className={`color text-base hover:cursor-pointer ${
                                    deviceId === item?.device_id
                                      ? "font-semibold underline decoration-sky-500 border-b-[0.1px] border-sky-500"
                                      : "font-normal "
                                  }`}
                                  onClick={() => {
                                    updateDevice(item?.device_id);
                                  }}
                                  key={item?.device_id}
                                  value={item?.device_id}
                                >
                                  {item?.device_name || "-"}
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      {/* {isRunning ? (
                        <div className="flex gap-[5px]">
                          <span className="hover:cursor-pointer py-1.5 px-3 border-[0.1px] border-[var(--text-recording)] font-normal text-[var(--text-primary-color)]">
                            Recording...
                          </span>
                          <span
                            className="bg-[var(--text-recording)] font-normal text-[var(--secondary-color)] px-2 flex justify-center items-center cursor-pointer"
                            onClick={btnStop}
                          >
                            Stop
                          </span>
                        </div>
                      ) : (
                        <div>
                          <button
                            className="hover:cursor-pointer  bg-[var(--text-recording)] disabled:cursor-not-allowed disabled:opacity-50 text-white font-normal py-2.5 px-3 text-xl "
                            disabled={userType === "instructor"}
                            // onClick={btnStart}
                            onClick={() => setIsOpen(true)}
                          >
                            Start Recording
                          </button>
                          {createPortal(
                            <AssesmentPanel
                              open={isopen}
                              onClose={() => setIsOpen(false)}
                              record={() => {
                                btnStart();
                                setIsOpen(false);
                              }}
                            ></AssesmentPanel>,
                            document.body
                          )}
                        </div>
                      )} */}
                    </div>
                  )}
                </Allotment.Pane>
              )}
            </Allotment>
          )}
          {/* upper section  */}
          {/* bottom section */}
          {/* {!hideSec ? ( */}
          <Allotment.Pane
            className={`bottom-left-pane pt-0 border-t-2 border-gray-200 bg-[var(--bg-ternary)] `}
            visible={hideSec}
            preferredSize={"30%"}
            minSize={100}
          >
            <PlayLearnTabsComponent
              ref={childRef}
              selectedManikin={deviceId}
              simulateSession={simulateSession}
              macId={macId}
              init={init}
              timer={timer}
              HideUpperSecsAction={HideUpperSecsAction}
              HideUpperSecs={HideUpperSecs}
              stopAnimation={stopAnimation}
              isRunning={isRunning}
              activeTabIndex={activeTabIndex}
              activeTabIndexSelection={activeTabIndexSelection}
              onTabClick={onTabClick}
              sessionIdData={sessionIdData}
              getSelectedSessionId={getSelectedSessionId}
              SwapBtn={SwapBtn}
              time={time}
              listingMessage={listingMessage}
              uSessionfilter={uSessionfilter}
              updateDeviceId={updateDevice}
              seekerListMsg={seekerListMsg}
              isOnline={isOnline}
              firstThreeSessionArray={firstThreeSessionArray}
              setFirstThreeSessionArray={setFirstThreeSessionArray}
              liveSessionState={liveSessionState}
              setLiveSessionState={setLiveSessionState}
              hideSec={hideSec}
              setHideSec={setHideSec}
              switch1={switch1}
              setSwitch1={setSwitch1}
              selectedSessionName={selectedSessionName}
              setSelectedSessionName={setSelectedSessionName}
              replayRunning={replayRunning}
              setReplayRunning={setReplayRunning}
            />
          </Allotment.Pane>
          {/* ) : (
                ""
              )} */}
          {/* bottom section */}
        </Allotment>
      </div>
    </>
  );
}

export default PlayAndLearn;
