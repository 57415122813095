import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ProgressBar from "../../components/Student-sec/progressbar/ProgressBar";

const ProgresBar = ({
  sessionDuration,
  currentTime,
  listingMessage,
  onClickSeeker,
}) => {
  const [seconds, setSeconds] = useState(parseInt(currentTime / 1000)); // this value is to use for progressbar progression
  const [currentTimeStamp, setCurrentTimeStamp] = useState(0); // this value shows the progressing time in timer
  const [totalTime, setTotalTime] = useState(0); // this value is to show total time duration for the replay
  function convertToMinutesAndSeconds(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}m : ${seconds}s`;
  }
  useEffect(() => {
    let getTotalTime = convertToMinutesAndSeconds(parseInt(sessionDuration));
    console.log(sessionDuration, "log for session duration");
    console.log(getTotalTime, " total time");
    setTotalTime(getTotalTime);
  }, [sessionDuration]);
  useEffect(() => {
    setSeconds(parseInt(currentTime / 1000));
    let getCurrentTime = convertToMinutesAndSeconds(
      parseInt(currentTime / 1000)
    );
    setCurrentTimeStamp(getCurrentTime);
  }, [currentTime]);
  return (
    // <progress value={seconds} max={sessionDuration} />
    <>
      {listingMessage.length > 0 && (
        <div className="flex items-center justify-around gap-2">
          <ProgressBar value={seconds} max={sessionDuration} />
          <div className="whitespace-nowrap text-textColor text-sm font-thin pl-5 ">
            <span>{currentTimeStamp}</span> / <span>{totalTime}</span>
          </div>
        </div>
      )}
    </>
    // <>
    //   {listingMessage.length > 0 && <div
    //     style={{ zIndex: "1000" }}
    //     id="toolTip"
    //     className="flex justify-between items-center w-[35rem] h-[0.1rem] bg-BgPrimary mb-[1rem] content-none"
    //   >
    //     <div
    //       style={{
    //         left: `${(seconds / sessionDuration) * 100}px`,
    //         zIndex: "1100",
    //       }}
    //       className="relative flex justify-center items-center  w-[0.8rem] h-[0.8rem]  bg-[var(--primary-color)]   rounded-full content-none"
    //     >
    //       <div className="rounded-full content-none bg-BgPrimary w-[0.3rem] h-[0.3rem]"></div>
    //     </div>
    //     {listingMessage?.map((v, i) => {
    //       return (
    //         <Tooltip
    //           title={`${v["message"]}  ${v["lapsedTime"]} ${v?.date}`}
    //           arrow
    //         >
    //           <div
    //             style={{
    //               left: `${(v["lapsedTime"] / sessionDuration) * 100}%`,
    //             }}
    //             className={` w-[0.3rem] h-[0.3rem] bg-black rounded-full content-none`}
    //             onClick={() =>
    //               onClickSeeker(v["lapsedTime"], v, i, listingMessage)
    //             }
    //           ></div>
    //           {/* <div style={{background:{color},left: `${(v["lapsedTime"] / sessionDuration) * 100}%`,
    //                             width:`${(v["lapsedTime"] / sessionDuration) * 100}rem`
    //                             }} className={`absolute top-[-0.1rem] border-blue-500 border-r-2 bg-red-300 h-[0.2rem] content-none`}></div>   */}
    //         </Tooltip>
    //       );
    //     })}
    //   </div>}
    //   {/* ${(v["lapsedTime"] / sessionDuration) * 40} */}
    // </>
  );
};

export default ProgresBar;
